<template>
    <div>
        <b-button-group class="mr-4">
            <!--Print-->
            <ControlPrintButton :disabled="isOffline"
                                :title="isOffline ? 'Gå online för att skriva ut' : 'Skriv ut'"
                                :controlId="control.id"
                                :activePartId="activePartId" />
            <!-- ./ Print-->
            <!--Notes-->
            <b-button variant="light"
                      class="border-gray-light"
                      v-b-tooltip.hover
                      title="Anteckningar"
                      @click="isNotesSidebarOpen = true;">

                <span>
                    <font-awesome-icon icon="comment-lines" />
                    <span v-show="hasNotes" class="fa-layers-counter exclamation">!</span>
                </span>
            </b-button>
            <b-sidebar id="sidebar--control-notes"
                       backdrop-variant="dark"
                       backdrop
                       :visible="isNotesSidebarOpen"
                       right
                       shadow
                       no-close-on-backdrop
                       no-close-on-esc
                       no-header-close>
                <b-form class="d-block text-center px-2"
                        @submit.prevent="onSubmitNotes">
                    <b-form-group id="sidebar--control-notes-form-group"
                                  label="Anteckningar"
                                  label-for="sidebar--control-notes-form">
                        <b-form-textarea id="sidebar--control-notes-form"
                                         :disabled="readOnly || disabled"
                                         style="height: 65vh;"
                                         v-model="controlNotes"
                                         rows="6" />
                    </b-form-group>

                    <!-- Files -->
                    <div class="text-left">
                        <FileUploader :entity-type="'controlNote' | getEntityTypeIdFromText"
                                      :read-only="readOnly || disabled"
                                      :entity-id="control.id"
                                      :isOffline="isOffline"
                                      :controlId="control.id"/>
                    </div>
                    <hr />
                    <div class="d-flex mt-3 justify-content-between">
                        <b-button variant="warning"
                                  @click="onCloseNotesSidebar">
                            Stäng
                        </b-button>
                        <b-button variant="success"
                                  v-if="!readOnly && !disabled"
                                  type="submit">Uppdatera</b-button>
                    </div>
                </b-form>
            </b-sidebar>
            <!-- ./ Notes-->

            <b-button v-if="!isControlDone"
                      v-b-tooltip.hover
                      variant="light"
                      @click="onSetAllPositionStatusesToDone"
                      class="border-gray-light"
                      :title="isAllControlled ? 'Alla positioner är redan kontrollerade' : 'Sätt alla till OK'"
                      :disabled="disabled || isAllControlled">
                <font-awesome-icon icon="check-double" />
            </b-button>
            <b-button v-if="!control.checkedOut && !disabled && !isControlDone"
                      class="border-gray-light"
                      variant="light"
                      v-b-tooltip.hover
                      title="Gör tillgänglig offline"
                      @click="onCheckOut">
                <font-awesome-icon icon="signal-slash" />
            </b-button>
        </b-button-group>
        <b-button-group v-b-tooltip.hover
             :title="setControlToDoneTitleText">
            <b-button style="width:105px;"
                      v-if="!isControlDone"
                      variant="light"
                      class="border-gray-light"
                      @click="$bvModal.show('modal--set-control-to-done')"
                      :disabled="!isAllControlled || disabled || isOffline">
                <font-awesome-icon icon="clipboard-check" /> Avsluta

                <b-modal id="modal--set-control-to-done"
                         hide-footer>
                    <template #modal-title>
                        Sluför kontroll
                    </template>
                    <b-form class="d-block text-center"
                            @submit.prevent="onSubmitModalSetControlToDone">
                        <b-form-group id="modal--form-changedDate-group"
                                      label="Välj datum"
                                      label-for="modal--form-changedDate">
                            <b-form-datepicker id="modal--form-changedDate"
                                               label-help="Du kan använda piltangenterna för att stega mellan datum"
                                               v-model="modalSetControlToDone.changedDate" />
                        </b-form-group>
                        <b-form-group id="modal--form-controlNotes-group"
                                      label="Anteckningar"
                                      label-for="modal--form-controlNotes">
                            <b-form-textarea id="modal--form-controlNotes"
                                             v-model="modalSetControlToDone.controlNotes"
                                             rows="6" />
                        </b-form-group>

                        <div class="d-flex mt-3 justify-content-between">
                            <b-button variant="warning"
                                      @click="closeModalSetControlToDone">
                                Avbryt
                            </b-button>
                            <b-button variant="success"
                                      type="submit"
                                      :disabled="disabled">Avsluta</b-button>
                        </div>
                    </b-form>
                </b-modal>
            </b-button>
        </b-button-group>
    </div>
</template>

<script>
    import ControlPrintButton from '@/components/control/ControlPrintButton';
    import FileUploader from '@/components/file/FileUploader';
    import { mapActions } from 'vuex';

    export default {
        name: 'ControlButtonGroupActions',
        props: {
            activePartId: {
                type: Number,
                default: null
            },
            control: Object,
            readOnly: Boolean,
            disabled: Boolean
        },
        watch: {
            controlNotes(newValue, oldValue) {
                this.isNotesSidebarDirty = newValue !== this.control.notes;
            }
        },
        components: {
            ControlPrintButton,
            FileUploader
        },
        data() {
            return {
                isNotesSidebarDirty: false,
                isNotesSidebarOpen: false,
                controlNotes: this.control.notes,
                modalSetControlToDone: {
                    controlId: this.control.id,
                    changedDate: new Date(),
                    controlNotes: this.control.notes
                }
            }
        },
        computed: {
            setControlToDoneTitleText() {
                if (this.isAllControlled && !this.isOffline) {
                    return 'Avsluta kontroll';
                }

                if (this.isOffline)
                    return 'Checka in för att kunna avsluta'

                if (!this.isAllControlled) {
                    return 'Du kan inte avsluta kontroller utan att kontrollera alla positioner';
                }
            },
            isAllControlled() {
                return this.control.positions.every(x => x.statusId > 0);
            },
            isControlDone() {
                return this.control.status >= 10;
            },
            hasNotes() {
                return typeof this.control.notes === 'string' && this.control.notes.length > 0
            },
            isOffline() {
                return this.control.checkedOut;
            }
        },
        methods: {
            ...mapActions('control', [
                'setAllPositionStatusesToDone',
                'checkOut',
                'setToDone',
                'setNotes'
            ]),
            /*Sidebar notes*/
            async onSubmitNotes() {
                this.setNotes(this.controlNotes)
                    .then(() => {
                        this.modalSetControlToDone.controlNotes = this.controlNotes;
                        this.closeSidebar();
                    })
                    .catch(() => alert('Något gick snett. Försök igen.'));
            },
            closeSidebar() {
                //this.controlNotes = this.control.notes;
                this.isNotesSidebarDirty = false;
                this.isNotesSidebarOpen = false;
            },
            async onCloseNotesSidebar() {
                if (this.isNotesSidebarDirty) {
                    if (await this.confirm('Du har osparade ändringar som kommer förloras. Vill du fortsätta ändå?'))
                        this.closeSidebar();
                } else {
                    this.closeSidebar();
                }
            },
            /* ./ Sidebar notes*/
            /*ModalSetControlToDone*/
            async onSubmitModalSetControlToDone() {
                this.setToDone({ done: this.modalSetControlToDone, controlNotes: this.modalSetControlToDone.controlNotes })
                    .then(() => {
                        this.controlNotes = this.modalSetControlToDone.controlNotes;
                        this.closeModalSetControlToDone();
                    })
                    .catch(() => alert('Något gick snett'));
            },
            closeModalSetControlToDone() {
                this.modalSetControlToDone.changedDate = new Date();
                this.$bvModal.hide('modal--set-control-to-done');
            },
            /*ModalSetControlToDone*/
            async onSetAllPositionStatusesToDone() {
                if (await this.confirm('Vill du sätta alla ännu ej kontrollerade punkter till OK?'))
                    this.setAllPositionStatusesToDone(this.activePartId);
            },
            async confirm(message) {
                return await this.$bvModal
                    .msgBoxConfirm(message, {
                        title: 'Är du säker?',
                        size: 'sm',
                        //buttonSize: 'sm',
                        cancelVariant: 'danger',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'primary',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        return confirmed
                    });
            },
            async onCheckOut() {
                if (await this.confirm('Vill du ladda ned egenkontrollen och jobba lokalt?')) {
                    await this.checkOut();
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "@/assets/scss/bootstrap-theme.scss";

    .fa-layers-counter.exclamation {
        background: getThemeColor("primary");
        font-size: 2.3rem;
        top: 2px;
        right: 2px;
    }
</style>