<template>
    <b-button :size="size"
              class="border-gray-light text-right"
              :variant="variant"
              :title="!isNavigatorOnline ? 'Du måste vara uppkopplad' : ''"
              v-b-tooltip.hover
              :disabled="!isNavigatorOnline && isOffline"
              @click="onToggleOfflineMode">{{isOffline ? 'Gå online' : 'Gå offline'}}</b-button>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'ToggleOfflineButton',
        props: {
            size: {
                type: String,
                required: false,
                default: 'sm'
            },
            variant: {
                type: String,
                required: false,
                default: 'success'
            }
        },
        data() {
            return {
                isNavigatorOnline: window.navigator.onLine
            }
        },
        computed: {
            ...mapState({
                isOffline: (state) => state.user.isOffline
            })
        },
        methods: {
            ...mapActions('user', [
                'toggleOfflineMode'
            ]),
            ...mapActions('control', [
                'checkInAll'
            ]),
            async onToggleOfflineMode() {
                const isConfirmed = await this.confirm(`${(this.isOffline
                    ? 'Du kommer gå online. Vill du även ladda upp alla egenkontroller?'
                    : 'Du kommer gå offline. Notera att applikationens funktionalitet kommer begränsas till att enbart kunna hantera egenkontroller som är nedladdade')}`);

                // Pressed X.
                if (isConfirmed === null)
                    return;

                // Is Offline.
                if (!this.isOffline && !isConfirmed)
                    return;
                else if (!this.isOffline && isConfirmed)
                    return await this.toggleOfflineMode();

                // Pressed No.
                if (!isConfirmed)
                    return await this.toggleOfflineMode();

                // Pressed Yes.
                if (!window.navigator.onLine)
                    return alert('Du saknar uppkoppling. Försök igen.');

                try {
                    // Check in all controls.
                    await this.checkInAll().then(errors => {
                        // Handle errors.
                        if (errors.length) {
                            // Build error message.
                            let errorMessage = '';
                            for (var i = 0; i < errors.length; i++)
                                errorMessage += '- ' + errors[i].error + '</br>';

                            const messageVNode = this.$createElement('div', { domProps: { innerHTML: errorMessage } })
                            // Show modal.
                            this.$bvModal.msgBoxOk([messageVNode], {
                                title: 'Information',
                                size: 'lg',
                                buttonSize: 'sm',
                                okVariant: 'primary',
                                headerClass: 'p-2 border-bottom-0',
                                footerClass: 'p-2 border-top-0',
                                centered: true
                            }).then(value => {
                                    this.boxTwo = value
                                });
                        }
                    });
                } catch {
                    alert('Något gick snett. Försök igen.');
                }

                return await this.toggleOfflineMode();
            },
            async confirm(message) {
                return await this.$bvModal
                    .msgBoxConfirm(message, {
                        title: 'Är du säker?',
                        size: 'sm',
                        //buttonSize: 'sm',
                        cancelVariant: 'danger',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'primary',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        return confirmed
                    });
            },
            updateOnlineStatus() {
                this.isNavigatorOnline = window.navigator.onLine;
            }
        },
        created() {
            window.addEventListener('online', this.updateOnlineStatus);
            window.addEventListener('offline', this.updateOnlineStatus);
        },
        destroyed() {
            window.addEventListener('online', this.updateOnlineStatus);
            window.addEventListener('offline', this.updateOnlineStatus);
        },
        mounted() {
            this.updateOnlineStatus();
        }
    };
</script>