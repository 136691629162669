<template>
    <b-button variant="light"
              class="border-gray-light"
              v-b-tooltip.hover="'Skriv ut'"
              target="_blank"
              :disabled="disabled"
              :to="{ name: 'control-print-view', params:{ controlId: controlId, partId: activePartId } }">
        <font-awesome-icon icon="print" />
    </b-button>
</template>

<script>
    export default {
        name: 'ControlPrintButton',
        props: {
            controlId: Number,
            activePartId: Number,
            disabled: Boolean
        }
    };
</script>